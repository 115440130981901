@import "../../../App.sass";
@import "../../../mq.sass";

.customers {

    .title {
        font-size: 40px;
        margin-bottom: 0;
        font-weight: bold;
        color: $v4green1;

        @include mobile {
            font-size: 28px; }

        @include tablet {
            font-size: 32px; } }

    .subtitle {
        width: 600px;
        font-size: 18px;
        margin-bottom: 0;
        color: $v4green1;
        font-family: $fontMerriweather;

        @include mobile {
            font-size: 14px;
            width: 100%;
            padding-right: 2rem; }

        @include tablet {
            font-size: 16px;
            width: 500px; } }

    .customer-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1.5rem;

        @include mobile {
            justify-content: center; }

        @include tablet {
            justify-content: center; }

        img {
            width: 100px; } }

    .customer-icons > img {
        margin: .5rem 2rem; } }
