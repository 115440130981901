@import '../../../App.sass';
@import '../../../mq.sass';

.topics {

    .title {
        font-size: 40px;
        color: $v4green1;
        font-weight: bold;
        margin-bottom: 0;

        @include mobile {
            font-size: 28px; }

        @include tablet {
            font-size: 32px; } }

    .subtitle {
        font-size: 18px;
        color: $v4green1;
        font-family: $fontMerriweather;
        margin-bottom: .5rem;
        width: 600px;

        @include mobile {
            font-size: 14px; }

        @include tablet {
            font-size: 16px;
            width: 500px; } }

    .topic-cards {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }

    .topic-card div:gap {
        margin: 70px; } }
