@import '../../../../App.sass';
@import '../../../../mq.sass';

.carousel {
    height: 100%;
    padding: 0 !important;
    z-index: 1;
    top: 150px !important;

    .slides {
        height: 90%;
        width: 100%;

        .slide {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            .profile {
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 3rem;

                .testi-pic {
                    height: 200px;
                    width: 200px;
                    border-radius: 5px; }

                .name {
                    text-align: center;
                    font-weight: bold;
                    margin: 0;
                    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.7)); }

                .company {
                    font-size: 25px;
                    font-weight: bold;
                    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.7)); } }

            .testimoni {
                width: 400px;
                height: 200px;
                font-size: 18px;
                filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.7));
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                p {
                    margin: 0; }

                .icon {
                    font-size: 10px;
                    margin-bottom: 0.5rem; }

                .icon:nth-child(1) {
                    margin-top: 0.5rem; }

                .text {
                    padding: 0 2rem;
                    display: table-cell;
                    vertical-align: middle; } } } } }

.slick-dots .slick-active a {
    color: $v4orange2; }

.slick-thumb li a {
    color: #b4b4b4; }

.slick-thumb li a:hover {
    color: $v4orange1; }

.slick-arrow {
    height: 50px;
    width: 50px;
    font-size: 25px;
    color: #ffffff; }

.slick-arrow:hover {
    color: $v4orange1; }

.slick-arrow:active {
    color: $v4orange2; }

.navigation-icon {
    display: block; }

.slick-prev {
    left: -50px; }

.slick-next {
    right: -50px; }

.slick-track {
    height: 100%; }

.slick-track div:nth-child(1) {
    height: 100%; }
