@import "../../../mq.sass";

.testimony {
    height: 100%;
    width: 100%;
    padding: 0 10rem;

    .title {
        z-index: 2;
        position: absolute;
        top: 20px;
        left: 4rem;
        font-size: 40px;
        color: #ffffff;
        font-weight: bold; }

    .carousel {
        top: 100px; } }

