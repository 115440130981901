@import '../../App.sass';
@import '../../mq.sass';

.article-page-wrapper {

    .article-page {

        .article-wrapper {

            .article {
                padding: 0 15rem;
                padding-bottom: 3rem;
                margin-top: 75px;
                margin-bottom: 4rem;
                border-bottom: 2px solid #DFDFDF;

                .title {
                    font-size: 28px; }

                .subtitle {
                    font-size: 18px;
                    color: #5F6368; }

                .image-wrapper {
                    text-align: end;
                    width: 100%;

                    .image {
                        width: 100%; }

                    .source {} } } }

        .more-section-wrapper {

            .more-section {

                .more {
                    font-size: 36px;
                    margin-bottom: 2rem;
                    font-weight: bold;
                    color: $v4green1; } } } } }
