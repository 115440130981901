@import '../../../App.sass';
@import '../../../mq.sass';

.services {

    .title {
        font-size: 40px;
        color: $v4green1;
        font-weight: bold;
        margin-bottom: 0;

        @include mobile {
            font-size: 28px; }

        @include tablet {
            font-size: 32px; } }

    .subtitle {
        width: 600px;
        font-size: 18px;
        color: $v4green1;
        font-family: $fontMerriweather;
        margin-bottom: 1.5rem;

        @include mobile {
            width: 100%;
            padding-right: 2rem;
            font-size: 14px;
            margin-bottom: .5rem; }

        @include tablet {
            font-size: 16px;
            width: 500px;
            margin-bottom: 1rem; } }

    .service-cards {
        padding: 2rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include mobile {
            gap: 1rem;
            justify-content: center; }

        @include tablet {
            gap: 1rem; } } }
